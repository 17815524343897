import { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from 'src/provider/API/call-service';
import utility from 'src/utils/utility'
import { CustomContainer, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { loansReportData, loansReportColumn } from 'src/utils/tableLayout';


const LoansReport = () => {
    const {API, downloadReport} = useContext(ApiContext)
    const [reportData, setReportData] = useState<any>(null)
    const [report, setReport] = useState<any>(null)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [resetPagination, setResetPagination] = useState<boolean>(false)


    const filterDataRef = useRef({})
    
    useEffect(() => {
        filterDataRef.current = {}
        handleFetchReport()
    }, [])

    async function handleFetchReport (pageNumber = 1, pageSize = 10, isDownload = false) {
        // const response = await API.getBillPaymentReport(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload})
        // if (response) {
        //     if (isDownload) {
        //         return downloadReport(response, 'LOANS_REPORT')
        //     }
        //     setReportData(response)
        //     setReport(helpers.addSerialNumberToItems(response?.data))
        // }
        setReportData({
            total: 4,
            data: loansReportData,
        })
        setReport(helpers.addSerialNumberToItems(loansReportData, {pageNumber, pageSize}))
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchReport()
        setResetPagination(!resetPagination)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3'>
                    <ServerSideTable
                        tableData={report}
                        tableColumns={loansReportColumn} 
                        dataFetchMethod={handleFetchReport}
                        totalRows={reportData?.total}
                        tableTitle='Loans Report'
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setShowFilter(true)}
                        canClear={handleClear}
                        downloadAction={() => handleFetchReport(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { showFilter &&
                <ReportFilter
                    type='loans'
                    onApplyFilter={handleFetchReport}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setShowFilter(false)} 
                />
            }
        </>
    )
}

export default utility.routeData('loans-report', 'Loans Report', <LoansReport/>)