import './style.scss'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import { 
    AppSpan, CustomContainer, FixedContainer, 
    GridContainer, SidePopupContainer 
} from "src/style"
import { UserTypes } from 'src/model'

export const AdminMenu: React.FC<any> = ({close}) => {
    const navigate = useNavigate()
    const {info: {sidebarMenuType, userType, role}, setInfoProperty} = useContext(AppInfoContext)
    const {showSideBar} = useContext(VisibilityContext)
    const [menuCategory, setMenuCategory] = useState('')
    const [user, setUser] = useState({userType, role})

    let adminUsers = [UserTypes.ADMIN, UserTypes.USER]
    let menuWithNoSubMenu = ['institutions', 'users']

    useEffect(() => {
        if (sidebarMenuType) setMenuCategory(sidebarMenuType)
    }, [sidebarMenuType])

    useEffect(() => {
        setUser({userType, role})
    }, [userType, role])

    async function handleClick (type: string) {
        await setInfoProperty('sidebarMenuType', type)
        if (menuWithNoSubMenu.includes(type)) {
            navigate(`/dashboard/${type}`)
        }
        else showSideBar(true)
        close()
    }
    
    return (
        <>
            <SidePopupContainer zIndex="7" onClick={close} bgColor='transparent' />
            <FixedContainer top="10" zIndex="8" className="admin-menu-fix-container">
                <CustomContainer
                    width="25" minHeight="18" bgColor="#ffffff"
                    borderColor="#F7FAFC" padding="1" topPadding="2" bottomPadding="2" radius="0.8"
                    shadow="0px 0px 1px rgba(12, 26, 75, 0.1), 0px 30px 40px rgba(20, 37, 63, 0.08)"
                >
                    { adminUsers.includes(user.userType) ?
                        <>
                            <GridContainer 
                                height="4" radius="0.6"
                                hoverBgColor="#E7F6F5"
                                bgColor={menuCategory === 'appConfig' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                                className='menu-type-toggle'
                                onClick={() => handleClick('appConfig')}
                            >
                                <AppSpan 
                                    fontWeight='600' cursor="pointer" 
                                    color={menuCategory === 'appConfig' ? '#0D968F' : '#667085'}
                                >
                                    App Configuration
                                </AppSpan>
                            </GridContainer>
                            <GridContainer 
                                height="4" radius="0.6"
                                hoverBgColor="#E7F6F5" topMargin='1'
                                bgColor={menuCategory === 'feedManagement' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                                className='menu-type-toggle'
                                onClick={() => handleClick('feedManagement')}
                            >
                                <AppSpan 
                                    fontWeight='600' cursor="pointer"
                                    color={menuCategory === 'feedManagement' ? '#0D968F' : '#667085'}
                                >
                                    Feed & Notification
                                </AppSpan>
                            </GridContainer>
                            <GridContainer 
                                height="4" radius="0.6"
                                hoverBgColor="#E7F6F5" topMargin='1'
                                bgColor={menuCategory === 'reports' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                                className='menu-type-toggle'
                                onClick={() => handleClick('reports')}
                            >
                                <AppSpan 
                                    fontWeight='600' cursor="pointer"
                                    color={menuCategory === 'reports' ? '#0D968F' : '#667085'}
                                >
                                    Reports
                                </AppSpan>
                            </GridContainer>
                        </>
                        :
                        <>
                            <GridContainer 
                                height="4" radius="0.6"
                                hoverBgColor="#E7F6F5" topMargin='1'
                                bgColor={menuCategory === 'institutions' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                                className='menu-type-toggle'
                                onClick={() => handleClick('institutions')}
                            >
                                <AppSpan 
                                    fontWeight='600' cursor="pointer"
                                    color={menuCategory === 'institutions' ? '#0D968F' : '#667085'}
                                >
                                    Institutions
                                </AppSpan>
                            </GridContainer>
                        </>
                    }
                    <GridContainer 
                        height="4" radius="0.6"
                        hoverBgColor="#E7F6F5" topMargin='1'
                        bgColor={menuCategory === 'audit' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                        className='menu-type-toggle'
                        onClick={() => handleClick('audit')}
                    >
                        <AppSpan 
                            fontWeight='600' cursor="pointer"
                            color={menuCategory === 'audit' ? '#0D968F' : '#667085'}
                        >
                            Audit
                        </AppSpan>
                    </GridContainer>
                    <GridContainer 
                        height="4" radius="0.6"
                        hoverBgColor="#E7F6F5" topMargin='1'
                        bgColor={menuCategory === 'users' ? '#E7F6F5' : 'rgba(0,0,0, 0.02)'}
                        className='menu-type-toggle'
                        onClick={() => handleClick('users')}
                    >
                        <AppSpan 
                            fontWeight='600' cursor="pointer"
                            color={menuCategory === 'users' ? '#0D968F' : '#667085'}
                        >
                            Users
                        </AppSpan>
                    </GridContainer>
                </CustomContainer>
            </FixedContainer>
        </>
    )
}