import './style.scss'
import { useState, useContext, useRef, useEffect } from 'react';
import utility from 'src/utils/utility'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import ApiContext from 'src/provider/API/call-service';
import { 
    AppLabel, AppSpan, AppText, Button, CustomContainer, 
    FlexColumn, FlexRow, Form, FormGroup, FormGroupsWrapper, 
    FormGroupWithIcon, GridContainer, ScreenContainer 
} from 'src/style';
import { ColorPicker, FirstAppDesign, RadioSelection, SecondAppDesign, ThirdAppDesign } from 'src/component';
import UploadIcon from 'src/assets/img/upload-icon.svg'
import helpers from 'src/utils/helpers';
import { ImgToBase64Result, UserTypes } from 'src/model';
import { RiCloseFill } from 'react-icons/ri';

const AppDetails = () => {
    const {notifier} = useContext(VisibilityContext)
    const {API} = useContext(ApiContext)
    const {info: {userType, institutionData}} = useContext(AppInfoContext)
    const [userRole, setUserRole] = useState('')
    const [input, setInput] = useState({
        appName: '', primaryColour: '#000000', primaryColour2: '#0EA59D',
        secondaryColour: '#E2FFFD', secondaryColour2: '#E2FFFD', logo: '', secondaryLogo: '',
        alias: '', appUrl: '', logoFileExtention: '', favIcon: '', favIconExtension: '',
        logoDarkFileExtention: '', urlType: 'Alias'
    })
    const [imgExtra, setImgExtra] = useState({
        logoName: '', logoUrl: '', favIconName: '', favIconUrl: '',
        logoSize: '', favIconSize: ''
    })
    const [currentColor, setCurrentColor] = useState('')
    const [showColorPicker, setShowColorPicker] = useState(false)
    const [inputsDisabled, setInputsDisabled] = useState(false);

    const colorRef = useRef<any>(null)

    useEffect(() => {
        if (institutionData) {
            const {
                    appName, alias, primaryColour, primaryColour2, secondaryColour, 
                    secondaryColour2, logo, favIcon
                } = institutionData

            setInput({
                ...input, appName, alias, primaryColour, primaryColour2,
                secondaryColour, secondaryColour2, logo, favIcon
            })

            const logoName = logo?.substring(logo?.lastIndexOf('/') + 1)?.split('?')[0];
            const favIconName = favIcon?.substring(favIcon?.lastIndexOf('/') + 1)?.split('?')[0];

            setImgExtra({
                ...imgExtra, logoUrl: logo, favIconUrl: favIcon,
                logoName, favIconName
            })
        }
        if (userType) {
            setUserRole(userType)
            if (userType != UserTypes.ADMIN) setInputsDisabled(true)
        }
    }, [institutionData, userType])

    async function handleFile (e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.files instanceof FileList) {
            if(!helpers.isValidFileType(e.target.value, ['png'])) return notifier.show('Only PNG file is allowed')

            const extension = helpers.getFileExtension(e.target.value)

            const uploadedFile = await helpers.compressImage(e.target.files[0])
            if (!uploadedFile) return notifier.show('Unable to compress large file size uploaded')

            helpers.convertFileToBase64(uploadedFile, true)
            .then(async (result: any) => {
                const dimensions = result.dimensions
                const {isValidResolution, message} = helpers.validateImgResolution(e.target.name, dimensions)
                
                if (!isValidResolution) {
                    return notifier.show(message)
                }

                setInput({
                    ...input, 
                    [e.target.name]: result.baseURL, 
                    [e.target.alt]: extension
                })

                setImgExtra({
                    ...imgExtra, 
                    [`${e.target.name}Name`]: e.target.files![0]?.name,
                    [`${e.target.name}Url`]: URL.createObjectURL(e.target.files![0]),
                    [`${e.target.name}Size`]: (e.target.files![0]?.size / 1024).toFixed(1),
                })
            })
            .catch(err => {
                notifier.show(JSON.stringify(err), 'Validation Error')
            })
        }
    }

    function handleColorClick (name: string) {
        //colorRef.current = name
        if (userRole === UserTypes.ADMIN) {
            setCurrentColor(name)
            setShowColorPicker(true)
        }
    }

    function handleSubmit (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()

        const {
            primaryColour, primaryColour2, secondaryColour,
            secondaryColour2, logo, favIcon
        } = input

        if (!primaryColour || !primaryColour2 || !secondaryColour || !secondaryColour2 || !logo || !favIcon) {
            return notifier.show('Kindly provide all required information')
        }

        API.updateAppDetails(input)
    }

    return (
        <>
            <ScreenContainer>
                <AppText textSize='1.9' color='#101828' fontWeight="700">Application Details</AppText>
                <Form onSubmit={handleSubmit}>
                    <FlexRow 
                        justifyContent='flex-start' alignItems='flex-start' topMargin='2' 
                        className='details-main-container'
                    >
                        <CustomContainer width='65' rightMargin='10' className='details-form-section'>
                            <FormGroup>
                                <label>App Name</label>
                                <input 
                                    placeholder="Enter App Name" 
                                    name='appName'
                                    value={input.appName}
                                    onChange={(e) => setInput({...input, appName: e.target.value})}
                                    disabled={inputsDisabled}
                                    required
                                />
                            </FormGroup>
                            <CustomContainer>
                                <RadioSelection 
                                    option1='Alias' 
                                    option2='Customized URL' 
                                    customClass='urlType-selection'
                                    output={(val: any) => setInput({...input, urlType: val})} 
                                    disabled={inputsDisabled}
                                />
                                <GridContainer 
                                    bgColor='#E7F6F5' minHeight='7' padding= '2.5' 
                                    radius='0.8' topMargin='1.5' bottomMargin='2'
                                    topPadding='1.5' bottomPadding='1.5' alignItems='center start'
                                >
                                    <AppText color='#0A756F' textSize='1.2'>
                                        {input.urlType === 'Alias' ? 
                                            'This option is the app name that is displayed when you use the qoreonline domain.' 
                                            : `This option should be selected when the bank needs their own personalized url. 
                                            You will be required to purchase your own domain and SSL certificate then submit those details below`
                                        }
                                    </AppText>
                                </GridContainer>
                                { input.urlType === 'Alias' ?
                                    <FormGroup>
                                        <label>Alias</label>
                                        <input 
                                            placeholder="Enter Alias" 
                                            name='alias'
                                            value={input.alias}
                                            onChange={(e) => setInput({...input, alias: e.target.value.replace(' ', '')})}
                                            disabled={inputsDisabled}
                                            required
                                        />
                                    </FormGroup>
                                    :
                                    <CustomContainer>
                                        <FormGroup>
                                            <label>App URL</label>
                                            <input 
                                                placeholder="Enter App URL" 
                                                name='appUrl'
                                                value={input.appUrl}
                                                onChange={(e) => setInput({...input, appUrl: e.target.value})}
                                                disabled={inputsDisabled}
                                                required
                                            />
                                        </FormGroup>
                                        <CustomContainer bottomMargin='2'>
                                            <AppLabel fontWeight='600' textSize='1.3'>Upload SSL Certificate</AppLabel>
                                            <FlexColumn 
                                                borderColor='#EAECF0' radius='0.8' minHeight='13' topMargin='0.7'
                                                padding='1.5'
                                            >
                                                <img src={UploadIcon} />
                                                <input 
                                                    type="file" name='logo' 
                                                    onChange={handleFile} 
                                                    className="logo-input" 
                                                    id="primary-logo" 
                                                    disabled={inputsDisabled}
                                                />
                                                <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='primary-logo'>Click to upload</AppLabel>
                                            </FlexColumn>
                                        </CustomContainer>
                                    </CustomContainer>
                                }
                            </CustomContainer>
                            <CustomContainer>
                                <AppText textSize='1.9' color='#101828' fontWeight="700" bottomMargin='2'>
                                    Colours
                                </AppText>
                                <FormGroupsWrapper className='full-column-flex-design'>
                                    <FormGroupWithIcon bottomMargin='2' rightMargin='3' width='48.5' className='full-width-flex-design'>
                                        <label>Primary Color 1</label>
                                        <div className="input-with-icon">
                                            <p onClick={() => handleColorClick('primaryColour')}>
                                                {input.primaryColour}
                                            </p>
                                            <CustomContainer 
                                                width='2.4' height='2.4' radius='0.3' bgColor={input.primaryColour}
                                                className="input-with-icon-tag" 
                                            />
                                        </div>
                                    </FormGroupWithIcon>
                                    <FormGroupWithIcon width='48.5' className='full-width-flex-design'>
                                        <label>Primary Color 2</label>
                                        <div className="input-with-icon">
                                            <p onClick={() => handleColorClick('primaryColour2')}>
                                                {input.primaryColour2 || '#00EFTF'}
                                            </p>
                                            <CustomContainer 
                                                width='2.4' height='2.4' radius='0.3' bgColor={input.primaryColour2}
                                                className="input-with-icon-tag" 
                                            />
                                        </div>
                                    </FormGroupWithIcon>
                                </FormGroupsWrapper>
                                <FormGroupsWrapper className='full-column-flex-design'>
                                    <FormGroupWithIcon rightMargin='3' width='48.5' className='full-width-flex-design'>
                                        <label>Secondary Color 1</label>
                                        <div className="input-with-icon">
                                            <p onClick={() => handleColorClick('secondaryColour')}>
                                                {input.secondaryColour || '#00EFTF'}
                                            </p>
                                            <CustomContainer 
                                                width='2.4' height='2.4' radius='0.3' bgColor={input.secondaryColour}
                                                className="input-with-icon-tag" 
                                            />
                                        </div>
                                    </FormGroupWithIcon>
                                    <FormGroupWithIcon width='48.5' className='full-width-flex-design'>
                                        <label>Secondary Color 2</label>
                                        <div className="input-with-icon">
                                            <p onClick={() => handleColorClick('secondaryColour2')}>
                                                {input.secondaryColour2 || '#00EFTF'}
                                            </p>
                                            <CustomContainer 
                                                width='2.4' height='2.4' radius='0.3' bgColor={input.secondaryColour2}
                                                className="input-with-icon-tag" 
                                            />
                                        </div>
                                    </FormGroupWithIcon>
                                </FormGroupsWrapper>
                            </CustomContainer>
                        </CustomContainer>
                        <ThirdAppDesign 
                            containerClass='app-details-phone' 
                            contentClass='app-details-content-container'
                            imgWrapClass='appDetails-third-design-img-wrap'
                        />
                        {/* <SecondAppDesign 
                            containerClass='app-details-phone'
                            contentClass='app-details-content-container' 
                            imgHeight='26.5'
                        /> */}
                        {/* <FirstAppDesign 
                            containerClass='app-details-phone'
                            contentClass='app-details-content-container' 
                            buttonMargin='3'
                            CircleImgClass='details-first-design-circle'
                        /> */}
                    </FlexRow>
                    <CustomContainer className='detail-logo-wrapper'>
                        <AppText textSize='1.9' color='#101828' fontWeight="700" bottomMargin='2'>
                            Logo
                        </AppText>
                        <FlexRow justifyContent='flex-start' rightPadding='10' className='details-logo-flex-wrapper'>
                            <CustomContainer 
                                width='65' 
                                className='details-logo-upload-flex-wrapper'
                            >
                                <FlexRow 
                                    justifyContent='flex-start' 
                                    alignItems='flex-start' gap='2.5'
                                    bottomMargin={!inputsDisabled ? '0' : '12'}
                                    className='details-upload-wrapper'
                                >
                                    <CustomContainer width='48' sizeUnit='%' className='details-upload-block'>
                                        <AppLabel fontWeight='600' textSize='1.3'>Primary Logo (White Theme)</AppLabel>
                                        { !input.logo ?
                                            <FlexColumn 
                                                borderColor='#EAECF0' radius='0.8' minHeight='13' topMargin='0.7'
                                                padding='1.5'
                                            >
                                                <img src={UploadIcon} />
                                                <input 
                                                    type="file" name='logo' alt='logoFileExtention' 
                                                    onChange={handleFile} 
                                                    className="logo-input" 
                                                    id="primary-logo" 
                                                    disabled={inputsDisabled}
                                                />
                                                <AppLabel 
                                                    color='#0A756F' fontWeight='600' topMargin='1' 
                                                    htmlFor='primary-logo' align='center'
                                                >
                                                    Click to upload
                                                </AppLabel>
                                                <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>PNG only</AppSpan>
                                            </FlexColumn>
                                            :
                                            <CustomContainer 
                                                borderColor='#0D968F' radius='0.8'
                                                padding='1.5' minHeight='13' topMargin='0.7'
                                                bgColor={!inputsDisabled ? 'transparent' : '#F7F8F9'}
                                            >
                                                <FlexRow 
                                                    justifyContent='space-between'
                                                    gap='2' bottomMargin='1'
                                                >
                                                    <CustomContainer width='6.8' height='3.1'>
                                                        <img 
                                                            src={imgExtra.logoUrl} className='ads-img' 
                                                            style={{objectFit: 'contain', objectPosition: 'left'}} 
                                                        />
                                                    </CustomContainer>
                                                    { !inputsDisabled &&
                                                        <AppSpan 
                                                            hoverColor='#B42318' cursor='pointer'
                                                            onClick={() => setInput({...input, logo: ''})}
                                                        >
                                                            <RiCloseFill fontSize='2rem' />
                                                        </AppSpan>
                                                    }
                                                </FlexRow>
                                                <AppText fontWeight='600'>{imgExtra.logoName}</AppText>
                                                { imgExtra.logoSize &&
                                                    <AppSpan color='#667085'>{imgExtra.logoSize} KB</AppSpan>
                                                }
                                            </CustomContainer>
                                        }
                                    </CustomContainer>
                                    <CustomContainer width='48' sizeUnit='%' className='details-upload-block'>
                                        <AppLabel fontWeight='600' textSize='1.3'>App Icon</AppLabel>
                                        { !input.favIcon ?
                                            <FlexColumn 
                                                borderColor='#EAECF0' radius='0.8' minHeight='13' topMargin='0.7'
                                                padding='1.5'
                                            >
                                                <img src={UploadIcon} />
                                                <input 
                                                    type="file" name='favIcon' alt='favIconExtension' 
                                                    onChange={handleFile} 
                                                    className="logo-input" 
                                                    id="favicon" 
                                                    disabled={inputsDisabled}
                                                />
                                                <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='favicon' align='center'>
                                                    Click to upload
                                                </AppLabel>
                                                <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>PNG (128 X 128) only</AppSpan>
                                            </FlexColumn>
                                            :
                                            <CustomContainer 
                                                borderColor='#0D968F' radius='0.8'
                                                padding='1.5' minHeight='13' topMargin='0.7'
                                                bgColor={!inputsDisabled ? 'transparent' : '#F7F8F9'}
                                            >
                                                <FlexRow 
                                                    justifyContent='space-between'
                                                    gap='2' bottomMargin='1'
                                                >
                                                    <CustomContainer width='6.8' height='3.1'>
                                                        <img 
                                                            src={imgExtra.favIconUrl} className='ads-img'
                                                            style={{objectFit: 'contain', objectPosition: 'left'}}  
                                                        />
                                                    </CustomContainer>
                                                    { !inputsDisabled &&
                                                        <AppSpan 
                                                            hoverColor='#B42318' cursor='pointer'
                                                            onClick={() => setInput({...input, favIcon: ''})}
                                                        >
                                                            <RiCloseFill fontSize='2rem' />
                                                        </AppSpan>
                                                    }
                                                </FlexRow>
                                                <AppText fontWeight='600'>{imgExtra.favIconName}</AppText>
                                                { imgExtra.favIconSize &&
                                                    <AppSpan color='#667085'>{imgExtra.favIconSize} KB</AppSpan>
                                                }
                                            </CustomContainer>
                                        }
                                    </CustomContainer>
                                </FlexRow>
                                {/* <CustomContainer topMargin='2.5' width='48' sizeUnit='%' className='details-upload-block'>
                                    <AppLabel fontWeight='600' textSize='1.3'>Secondary Logo (Dark Theme)</AppLabel>
                                    <FlexColumn 
                                        borderColor='#EAECF0' radius='0.8' minHeight='13' topMargin='0.7'
                                        padding='1.5'
                                    >
                                        <img src={UploadIcon} />
                                        <input type="file" name='secondaryLogo' alt='logoDarkFileExtention' onChange={handleFile} className="logo-input" id="secondary-logo" />
                                        <AppLabel color='#0A756F' fontWeight='600' topMargin='1' htmlFor='secondary-logo' align='center'>
                                            {input.secondaryLogo ? 'Uploaded! Click to Replace' : 'Click to upload'}
                                        </AppLabel>
                                        <AppSpan color='#667085' textSize='1.2' topMargin='0.3'>PNG preferred</AppSpan>
                                    </FlexColumn>
                                </CustomContainer> */}
                                { userRole === UserTypes.ADMIN &&
                                    <FlexRow justifyContent='flex-end' topMargin='3'>
                                        <Button
                                            width='8'
                                            titleSize='1.4' fontWeight='600'
                                            height='4' radius='0.8'
                                            bottomMargin='3.5' hoverColor='#0D968F'
                                            hoverBgColor='#fff' borderColor='#0D968F'
                                        >
                                            Submit
                                        </Button>
                                    </FlexRow>
                                }
                            </CustomContainer>
                            <ThirdAppDesign 
                                containerClass='app-details-phone2' 
                            />
                        </FlexRow>
                    </CustomContainer>
                </Form>
            </ScreenContainer>
            { showColorPicker &&
                <ColorPicker 
                    close={() => setShowColorPicker(false)}
                    output={(color: string) => setInput({...input, [currentColor]: color})}
                />
            }
        </>
    )
}

export default utility.routeData('app-details', 'Application Details', <AppDetails/>)