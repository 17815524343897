import { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import utility from 'src/utils/utility'
import { AppText, Button, CustomContainer, FlexRow, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { usersData, usersColumn } from 'src/utils/tableLayout';
import { CreateUser } from 'src/popup';
import { User, UserTypes } from 'src/model';
import { useNavigate } from 'react-router-dom';

const Users = () => {
    const navigate = useNavigate()
    const {API, downloadReport} = useContext(ApiContext)
    const {info: {userType, role}, setInfoProperty} = useContext(AppInfoContext)
    const [reportData, setReportData] = useState<any>(null)
    const [report, setReport] = useState<any>(null)
    const [user, setUser] = useState({role: '', type: ''})
    const [view, setView] = useState<string>('')
    const [resetPagination, setResetPagination] = useState<boolean>(false)

    const filterDataRef = useRef({})

    useEffect(() => {
        if (userType) setUser({role: role, type: userType})
    }, [userType, role])
    
    useEffect(() => {
        filterDataRef.current = {}
        if (userType) handleFetchReport()
    }, [userType])

    async function handleFetchReport (pageNumber = 1, pageSize = 10, isDownload = false) {
        const response = userType === UserTypes.SUPER_ADMIN ?  
            await API.getAllAdminUsers(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload})
            : await API.getAdminUsers(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload})

        if (response) {
            if (isDownload) {
                response.data = helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize}, true, 'user')
                return downloadReport(response, 'USERS_REPORT')
            }
            setReportData(response)
            setReport(helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize}, true, 'user'))
        }
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchReport()
        setResetPagination(!resetPagination)
    }

    function handleRepushEmail (row: any) {
        API.repushEmail(row.ibInstitutionCode, row.id)
    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3' className='no-submenu-container'>
                    <FlexRow 
                        justifyContent='space-between' alignItems='flex-start' 
                        bottomMargin='1' gap='1.5' wrap='wrap'
                    >
                        <AppText textSize='3' color='#101828' fontWeight="600">
                            User Profiles
                        </AppText>
                        { (user.type != UserTypes.USER && user.role === user.type) &&
                            <Button
                                width='11.3'
                                titleSize='1.4' fontWeight='600'
                                height='4' radius='0.8' hoverColor='#0D968F'
                                hoverBgColor='#fff' borderColor='#0D968F'
                                onClick={() => setView('create-user')}
                            >
                                Create User
                            </Button>
                        }
                    </FlexRow>
                    <ServerSideTable
                        tableData={report}
                        tableColumns={usersColumn(user.type === UserTypes.SUPER_ADMIN ? handleRepushEmail : null)} 
                        dataFetchMethod={handleFetchReport}
                        totalRows={reportData?.total}
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setView('report')}
                        canClear={handleClear}
                        downloadAction={() => handleFetchReport(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { view === 'report' &&
                <ReportFilter
                    type='users'
                    top={26}
                    onApplyFilter={handleFetchReport}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setView('')} 
                />
            }
            { view === 'create-user' &&
                <CreateUser
                    getUsers={() => {
                        filterDataRef.current = {};
                        handleFetchReport()
                    }}
                    close={() => setView('')}
                />
            }
        </>
    )
}

export default utility.routeData('users', 'Users', <Users/>)