import './style.scss'
import { useContext, useEffect, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { AppSpan, AppText, Button, Circle, FixedContainer, FlexColumn, FlexRow, GridContainer, MenuIcon } from "src/style"
import { Logo } from "src/assets/icons"
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import ApiContext from 'src/provider/API/call-service'
import { LogoutUI, RemoteUser } from 'src/popup'
import { HiOutlineChevronDoubleRight } from 'react-icons/hi'
import { BsBell } from "react-icons/bs";
import { AccountAction, AdminMenu } from 'src/component'
import UserPics from 'src/assets/img/rhydhur_pic.jpg'
import { UserTypes } from 'src/model'


export const Header: React.FC = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {visibility, showSideBar} = useContext(VisibilityContext)
    const {info: {sidebarMenuType, userType, role}, setInfoProperty} = useContext(AppInfoContext)
    const [navType, setNavType] = useState('')
    const [user, setUser] = useState({userType, role})
    const [view, setView] = useState('')

    let menuWithNoSubMenu = ['institutions', 'users']
    let adminUsers = [UserTypes.ADMIN, UserTypes.USER]

    useEffect(() => {
        if (sidebarMenuType) setNavType(sidebarMenuType)
    }, [sidebarMenuType])

    useEffect(() => {
        setUser({userType, role})
    }, [userType, role])

    function handleMenuType(type: string) {
        setNavType(type)
        setInfoProperty('sidebarMenuType', type)
        
        if (menuWithNoSubMenu.includes(type)) {
            navigate(`/dashboard/${type}`)
        }
    }

    return (
        <>
            <MenuIcon onClick={() => showSideBar(!visibility.isSideBar)} />
            <FixedContainer 
                top="0" left="0" width="100" sizeUnit="%"
                className="header-fix-container" zIndex="3"
            >
                <FlexColumn 
                    height="8" bgColor="#ffffff" leftPadding="10" rightPadding="10"
                    className="header-wrapper"
                >
                    <FlexRow justifyContent="space-between">
                        <FlexRow justifyContent="flex-start" width='auto' sizeUnit=''>
                            <Logo className="header-logo" />
                            {/* <AppText 
                                color='#667085' textSize="1.6" fontWeight="600" leftMargin="3.5"
                                className="header-label"
                            >
                                Client Mobile & Internet Banking Setup
                            </AppText> */}
                            <FlexRow leftMargin="3.5" gap='1' width='auto' sizeUnit='' className='admin-menu'>
                                { adminUsers.includes(user.userType) ?
                                    <>
                                        <GridContainer 
                                            width='15' radius="0.6" height='3.6'
                                            bgColor={navType === 'appConfig' ? '#E7F6F5' : 'transparent'}
                                            onClick={() => handleMenuType('appConfig')}
                                            className='menu-type-toggle'
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor="pointer"
                                                color={navType === 'appConfig' ? '#0D968F' : '#667085'}
                                            >
                                                App Configuration
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer 
                                            width='16' radius="0.6" height='3.6'
                                            bgColor={navType === 'feedManagement' ? '#E7F6F5' : 'transparent'}
                                            onClick={() => handleMenuType('feedManagement')}
                                            className='menu-type-toggle'
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor="pointer"
                                                color={navType === 'feedManagement' ? '#0D968F' : '#667085'}
                                            >
                                                Feeds & Notifications
                                            </AppSpan>
                                        </GridContainer>
                                        <GridContainer 
                                            width='7.8' radius="0.6" height='3.6'
                                            bgColor={navType === 'reports' ? '#E7F6F5' : 'transparent'}
                                            onClick={() => handleMenuType('reports')}
                                            className='menu-type-toggle'
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor="pointer"
                                                color={navType === 'reports' ? '#0D968F' : '#667085'}
                                            >
                                                Reports
                                            </AppSpan>
                                        </GridContainer>
                                    </>
                                    :
                                    <>
                                        <GridContainer 
                                            width='10.1' radius="0.6" height='3.6'
                                            bgColor={navType === 'institutions' ? '#E7F6F5' : 'transparent'}
                                            onClick={() => handleMenuType('institutions')}
                                            className='menu-type-toggle'
                                        >
                                            <AppSpan 
                                                fontWeight='600' cursor="pointer"
                                                color={navType === 'institutions' ? '#0D968F' : '#667085'}
                                            >
                                                Institutions
                                            </AppSpan>
                                        </GridContainer>
                                    </>
                                }
                                <GridContainer 
                                    width='6.1' radius="0.6" height='3.6'
                                    bgColor={navType === 'audit' ? '#E7F6F5' : 'transparent'}
                                    onClick={() => handleMenuType('audit')}
                                    className='menu-type-toggle'
                                >
                                    <AppSpan 
                                        fontWeight='600' cursor="pointer"
                                        color={navType === 'audit' ? '#0D968F' : '#667085'}
                                    >
                                        Audit
                                    </AppSpan>
                                </GridContainer>
                                <GridContainer 
                                    width='6.2' radius="0.6" height='3.6'
                                    bgColor={navType === 'users' ? '#E7F6F5' : 'transparent'}
                                    onClick={() => handleMenuType('users')}
                                    className='menu-type-toggle'
                                >
                                    <AppSpan 
                                        fontWeight='600' cursor="pointer"
                                        color={navType === 'users' ? '#0D968F' : '#667085'}
                                    >
                                        Users
                                    </AppSpan>
                                </GridContainer>
                            </FlexRow>
                            <FlexRow 
                                gap='0.5' width='auto' alignItems='center'
                                sizeUnit='' leftMargin='1.5'
                                className='admin-menu-toggle'
                                style={{zIndex: 3}}
                                onClick={() => setView('admin-menu')}
                            >
                                <AppText
                                    textSize='1.7' fontWeight='600' color='#25282B' 
                                    cursor='pointer' hoverColor='#0D968F'
                                >
                                    Admin Menu
                                </AppText>
                                <AppSpan textSize='2' color='#0D968F' cursor='pointer' bottomMargin='0.5'>
                                    <HiOutlineChevronDoubleRight />
                                </AppSpan>
                            </FlexRow>
                        </FlexRow>
                        <FlexRow 
                            width='auto' sizeUnit='' justifyContent='flex-start' gap='2.5'
                        >
                            { !adminUsers.includes(user.userType) &&
                                <Button
                                    width='14'
                                    titleSize='1.4' fontWeight='600'
                                    height='4' radius='0.8' color='#344054'
                                    bgColor='#fff' borderColor='#D0D5DD'
                                    className='update-billers-btn'
                                    onClick={() => API.updateBillsPayment()}
                                >
                                    Update Biller API
                                </Button>
                            }
                            <AppSpan textSize='2.4'>
                                <BsBell />
                            </AppSpan>
                            <Circle 
                                size="4" bgColor='#F9FAFB'
                                style={{overflow: 'hidden'}}
                                onClick={() => setView('account-action')}
                            >
                                <img src={UserPics} className='ads-img' />
                            </Circle>
                        </FlexRow>
                    </FlexRow>
                </FlexColumn>
            </FixedContainer>
            { view === 'logout-UI' &&
                <LogoutUI
                    close={() => setView('')} 
                />
            }
            { view === 'admin-menu' &&
                <AdminMenu
                    close={() => setView('')} 
                />
            }
            { view === 'remote-user' &&
                <RemoteUser
                    close={() => setView('')} 
                />
            }
            { view === 'account-action' &&
                <AccountAction
                    close={() => setView('')} 
                    switchRemote={() => setView('remote-user')}
                />
            }
        </>
    )
}