import * as IoIcons from 'react-icons/io'

export const sideBarData = [
    {
        title: 'Theme Design',
        path: '/dashboard/app-design',
        menuCategory: 'appConfig'
    },
    {
        title: 'Application Details',
        path: '/dashboard/app-details',
        menuCategory: 'appConfig'
    },
    {
        title: 'Onboarding Slides',
        path: '/dashboard/onboarding-slides',
        menuCategory: 'appConfig'
    },
    {
        title: 'Configuration',
        path: '/dashboard/configuration',
        menuCategory: 'appConfig'
    },
    {
        title: 'Feature Control',
        path: '/dashboard/feature-control',
        menuCategory: 'appConfig'
    },
    {
        title: 'App Generation',
        path: '/dashboard/app-generation',
        menuCategory: 'appConfig'
    },
    // {
    //     title: 'Manage Ads',
    //     path: '/dashboard/ads-management',
    //     menuCategory: 'feedManagement'
    // },
    {
        title: 'Feeds',
        path: '',
        iconClosed: <IoIcons.IoIosArrowDown/>,
        iconOpened: <IoIcons.IoIosArrowUp/>,
        menuCategory: 'feedManagement',
        subNav: [
            {
                title: 'Web Banking',
                path: '/dashboard/ibanking-feed',
            },
            {
                title: 'Mobile Banking',
                path: '/dashboard/mbanking-feed',
            },
        ]
    },
    {
        title: 'Notification',
        path: '/dashboard/notification',
        menuCategory: 'feedManagement'
    },
    {
        title: 'Customer Profile',
        path: '/dashboard/customer-profile-report',
        menuCategory: 'reports'
    },
    {
        title: 'Customer Login',
        path: '/dashboard/customer-login-report',
        menuCategory: 'reports'
    },
    {
        title: 'Failed Transactions',
        path: '/dashboard/failed-tx-report',
        menuCategory: 'reports'
    },
    {
        title: 'Transfer',
        path: '/dashboard/transfer-report',
        menuCategory: 'reports'
    },
    {
        title: 'Bills payment',
        path: '/dashboard/bill-payment-report',
        menuCategory: 'reports'
    },
    {
        title: 'Airtime',
        path: '/dashboard/airtime-report',
        menuCategory: 'reports'
    },
    {
        title: 'Transaction Limit',
        path: '/dashboard/tx-limit-report',
        menuCategory: 'reports'
    },
    {
        title: 'Fixed Deposit',
        path: '/dashboard/fixed-deposit-report',
        menuCategory: 'reports'
    },
    // {
    //     title: 'Cardless Withrawals',
    //     path: '/dashboard/cardless-withdrawal-report',
    //     menuCategory: 'reports'
    // },
    // {
    //     title: 'Loans',
    //     path: '/dashboard/loans-report',
    //     menuCategory: 'reports'
    // },
    {
        title: 'Target Savings',
        path: '/dashboard/target-savings-report',
        menuCategory: 'reports'
    },
    {
        title: 'Audit Reports',
        path: '/dashboard/audit-report',
        menuCategory: 'audit'
    },
    {
        title: 'Login Reports',
        path: '/dashboard/login-report',
        menuCategory: 'audit'
    },
    {
        title: 'Account Upgrade',
        path: '/dashboard/account-upgrade-requests',
        menuCategory: 'reports'
    },
]