import './style.scss'
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { AppLabel, AppSpan, AppText, Button, CustomContainer, FlexRow, FormGroup, FormGroupWithIcon, Switch} from 'src/style'
import { IoIosArrowBack } from "react-icons/io";
import utility from 'src/utils/utility';
import helpers from 'src/utils/helpers';
import { FiChevronDown } from 'react-icons/fi';

const InstitutionDetails = () => {
    const navigate = useNavigate()
    const {API} = useContext(ApiContext)
    const {info: {selectedInstitution}} = useContext(AppInfoContext)
    const [institution, setInstitution] = useState<any>(null)
    const [input, setInput] = useState({
        newCustomerOnboardingType: '', existingCustomerOnboardingType: '',
        migrationStartDateTime: '', migrationEndDateTime: '',
        ibInstitutionCode: selectedInstitution.id, alias: selectedInstitution.alias
    })

    useEffect(() => {
        if (selectedInstitution) {
            setInstitution(selectedInstitution)
            handleFetchInstitution()
        }
    }, [selectedInstitution])

    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLSelectElement>) => setInput({...input, [e.target.name]: e.target.value})

    async function handleFetchInstitution () {
        const response = await API.getInstitutionById(selectedInstitution.id, false)
        if (response) {
            let [migrationStartDate, migrationEndDate] = ['', '']
            
            if (response.migrationInfo.migrationStartDate) {
                migrationStartDate = response.migrationInfo.migrationStartDate?.split('T')[0]
            }
            if (response.migrationInfo.migrationEndDate) {
                migrationEndDate = response.migrationInfo.migrationEndDate?.split('T')[0]
            }
            
            setInstitution(response)
            setInput({
                ...input, 
                newCustomerOnboardingType: response.newCustomerOnboardingType, 
                existingCustomerOnboardingType: response.existingCustomerOnboardingType,
                migrationStartDateTime: migrationStartDate,
                migrationEndDateTime: migrationEndDate
            })
        }
    }

    async function handleUpdate () {
        const response = await API.updateOnboardingFlowAndMigrationInfo(input)
        if (response) navigate(-1)
    }

    return (
        <>
            <CustomContainer 
                className='profile-container'
            >
                <CustomContainer>
                    <FlexRow justifyContent='space-between' gap='2' wrap='wrap'>
                        <FlexRow 
                            justifyContent='flex-start' gap='1'
                            width='auto' sizeUnit=''
                            onClick={() => navigate(-1)}
                        >
                            <AppSpan textSize='2' cursor='pointer' >
                                <IoIosArrowBack />
                            </AppSpan>
                            <AppText 
                                textSize='3' fontWeight='600' color='#101828' cursor='pointer'
                            >
                                Institution Details
                            </AppText>
                        </FlexRow>
                        <Button
                            width='9'
                            titleSize='1.4' fontWeight='600'
                            height='4' radius='0.8'
                            bottomMargin='1' hoverColor='#0D968F'
                            hoverBgColor='#fff' borderColor='#0D968F'
                            onClick={handleUpdate}
                        >
                            Update
                        </Button>
                    </FlexRow>
                    
                    <CustomContainer>
                        <FlexRow
                            bgColor='#ffffff' minHeight='10.6' alignItems='flex-start'
                            radius='0.8' padding='2' justifyContent='flex-start'
                            gap='6' wrap='wrap' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                        >
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Institution</AppText>
                                <AppSpan color='#A098AE'>{institution?.name}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Alias</AppText>
                                <AppSpan color='#A098AE'>{institution?.alias}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Phone Number</AppText>
                                <AppSpan color='#A098AE'>{institution?.correspondencePhone}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Email Address</AppText>
                                <AppSpan color='#A098AE'>{institution?.correspondenceEmail}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Institution Code</AppText>
                                <AppSpan color='#A098AE'>{institution?.institutionCode}</AppSpan>
                            </CustomContainer>
                            <CustomContainer width='auto' sizeUnit=''>
                                <AppText color='#58595B' fontWeight='700'>Setup Date</AppText>
                                <AppSpan color='#A098AE'>{institution?.setupDate && helpers.handleDate(institution?.setupDate)}</AppSpan>
                            </CustomContainer>
                        </FlexRow>
                        <CustomContainer
                            topMargin='2' borderColor='#EAECF0'
                            shadow='0px 1px 2px 0px #1018280F'
                            bgColor='#ffffff' minHeight='48.5'
                            padding='2' radius='0.8'
                        >
                            <CustomContainer>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Configuration
                                </AppLabel>
                                <FlexRow 
                                    topMargin='1' gap='2' 
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    className='institution-config-item'
                                >
                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>New Customer Onboarding</AppSpan>
                                        <FormGroupWithIcon bottomMargin='0' topMargin='2'>
                                            <label>Select Process Flow</label>
                                            <div 
                                                style={{height: '4rem', backgroundColor: '#F9FAFB'}}
                                                className="input-with-icon"
                                            >
                                                <select 
                                                    name='newCustomerOnboardingType'
                                                    value={input.newCustomerOnboardingType}
                                                    onChange={handleInput}
                                                    style={{height: '4rem'}}
                                                    autoComplete='off'
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Nigeria with Facematch</option>
                                                    <option value="2">Nigeria without Facematch</option>
                                                    <option value="3">Gambia - RFS</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </CustomContainer>

                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>Existing Customer Onboarding</AppSpan>
                                        <FormGroupWithIcon bottomMargin='0' topMargin='2'>
                                            <label>Select Process Flow</label>
                                            <div 
                                                style={{height: '4rem', backgroundColor: '#F9FAFB'}}
                                                className="input-with-icon"
                                            >
                                                <select 
                                                    name='existingCustomerOnboardingType'
                                                    value={input.existingCustomerOnboardingType}
                                                    onChange={handleInput}
                                                    style={{height: '4rem'}}
                                                    autoComplete='off'
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Nigeria</option>
                                                    <option value="2">Ethiopia - Akufada</option>
                                                </select>
                                                <FiChevronDown className="input-with-icon-tag" />
                                            </div>
                                        </FormGroupWithIcon>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>

                            <CustomContainer topMargin='2'>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Migration
                                </AppLabel>
                                <CustomContainer
                                    borderColor='#EAECF0' topMargin='1' 
                                    shadow='0px 1px 2px 0px #1018280F'
                                    padding='2' radius='0.8' minHeight='11.6'
                                    leftPadding='1.7' rightPadding='1.7'
                                >
                                    <FlexRow 
                                        gap='2' 
                                        justifyContent='space-between'
                                        alignItems='stretch'
                                        className='institution-config-item'
                                    >
                                        <FormGroup 
                                            bottomMargin='0' height='4'
                                            width='49.5'
                                        >
                                            <label>Start Date</label>
                                            <input 
                                                name='migrationStartDateTime'
                                                type='date'
                                                style={{color: input.migrationStartDateTime ? '#000000' : '#667085', backgroundColor: '#F9FAFB'}}
                                                value={input.migrationStartDateTime}
                                                onChange={handleInput}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                        <FormGroup 
                                            bottomMargin='0' height='4'
                                            width='49.5'
                                        >
                                            <label>End Date</label>
                                            <input 
                                                name='migrationEndDateTime'
                                                type='date'
                                                style={{color: input.migrationEndDateTime ? '#000000' : '#667085', backgroundColor: '#F9FAFB'}}
                                                value={input.migrationEndDateTime}
                                                onChange={handleInput}
                                                autoComplete="off"
                                            />
                                        </FormGroup>
                                    </FlexRow>
                                    <FlexRow 
                                        topMargin='2.5' gap="1.5" 
                                        alignItems="flex-start" justifyContent="flex-start"
                                    >
                                        <AppSpan fontWeight="600" color="#344054">Migration Completion</AppSpan>
                                        <Switch
                                            radius='1.17' bgColor='#E7E7E7' 
                                            checkedBgColor='#0D968F' checkedSliderBgColor="#ffffff"
                                        >
                                            <input 
                                                type="checkbox" 
                                                checked={institution?.migrationInfo?.migrationCompleted}
                                            />
                                            <div ></div>
                                            <span></span>
                                        </Switch>
                                    </FlexRow> 
                                </CustomContainer>
                            </CustomContainer>

                            <CustomContainer topMargin='2'>
                                <AppLabel 
                                    color='#101828' textSize='1.8' 
                                    fontWeight='700'
                                >
                                    Other Information
                                </AppLabel>
                                <FlexRow 
                                    topMargin='1' gap='2' 
                                    justifyContent='space-between'
                                    alignItems='stretch'
                                    className='institution-config-item'
                                >
                                    <CustomContainer
                                        borderColor='#EAECF0'
                                        shadow='0px 1px 2px 0px #1018280F'
                                        padding='2.5' radius='0.8' minHeight='15.9'
                                        leftPadding='1.7' rightPadding='1.7'
                                        width='49.5' sizeUnit='%'
                                    >
                                        <AppSpan color='#434344'>Update Details</AppSpan>
                                        <FormGroup 
                                            topMargin="1.5" bgColor='#F9FAFB'
                                        >
                                            <label>Institution Alias</label>
                                            <input 
                                                placeholder="Enter alias" 
                                                name='alias'
                                                value={input.alias}
                                                onChange={handleInput}
                                                required
                                            />
                                        </FormGroup>
                                    </CustomContainer>
                                </FlexRow>
                            </CustomContainer>
                        </CustomContainer> 
                    </CustomContainer>
                </CustomContainer>
            </CustomContainer>
        </>
    )
}

export default utility.routeData('institution-details', 'Institution Details', <InstitutionDetails/>)