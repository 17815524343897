import { useState, useContext } from "react";
import { RiCloseFill } from "react-icons/ri"
import ApiContext from "src/provider/API/call-service";
import AppInfoContext from "src/provider/state-manager/appInfoProvider";
import VisibilityContext from "src/provider/state-manager/visibilityProvider";
import { AbsoluteContainer, AppText, Button, CustomContainer, FlexColumn, FlexRow, Form, FormGroupWithIcon, GridContainer, ReactSelect, SidePopupContainer } from "src/style"
// import { FiChevronDown } from "react-icons/fi";
import {ReactComponent as Banner} from 'src/assets/img/remote-banner.svg'
import {ReactComponent as AdminIcon} from 'src/assets/img/admin-view-icon.svg'
import {ReactComponent as InstitutionIcon} from 'src/assets/img/institution-icon.svg'
import utility from "src/utils/utility";
import helpers from "src/utils/helpers";


export const RemoteUser: React.FC<any> = ({close}) => {
    const {API} = useContext(ApiContext)
    const {info, setInfoProperty} = useContext(AppInfoContext)
    const {notifier} = useContext(VisibilityContext)
    const [remoteType, setRemoteType] = useState('')
    const [allInstitutions, setAllInstitutions] = useState<any>([])
    const [institution, setInstitution] = useState<any>(null)

    async function handleFetchInstitution () {
        const response = await API.getAllInstitutions()
        if (response.length) setAllInstitutions(helpers.sortItemsWIthString(response, 'name', 'asc'))
    }

    async function handleRemoteType (remoteType: string) {
        setRemoteType(remoteType)
        if (remoteType === 'SA') {
            setInfoProperty('userType', remoteType)
            close()
        }
        else handleFetchInstitution()
    }

    async function handleInstitutionRemote () {
        if (!institution.value) return notifier.show('Kindly select an institution')

        const response = await API.getInstitutionById(institution.value)
        if (response) {
            setInfoProperty('userType', 'User')
            close()
        }
    }

    return (
        <>
            <SidePopupContainer zIndex="8" onClick={close} />
            <FlexColumn
                className="new-ads-container"
                justifyContent="flex-start"
                alignItems="flex-start" 
            >
                <AbsoluteContainer top='1.5' right='1.5'>
                    <Button 
                        titleSize="2" fontWeight="500"
                        width='3.4' shadow='none'
                        height='3.4'
                        radius='0.4'
                        bgColor='#ffffff'
                        hoverBgColor='#FEF3F2'
                        borderColor='none'
                        color='#000000'
                        hoverColor='#B42318'
                        onClick={close}
                    >
                        <RiCloseFill style={{fontSize: '2.3rem'}} />
                    </Button> 
                </AbsoluteContainer>
                <AppText color='#101828' textSize="1.8" fontWeight="700" fontFamily="Gilmer-Bold">
                    Remote
                </AppText>
                <CustomContainer>
                    <CustomContainer
                        maxHeight='11.5' radius='0.46' bottomMargin="1"
                        height='11.5' topMargin='2'
                    >
                        <Banner style={{width: '100%'}} />
                    </CustomContainer>
                    <CustomContainer>
                        <GridContainer 
                            height="7.5" borderColor="#EAECF0" radius="0.8" bottomMargin="1.5" 
                            leftPadding="2" rightPadding="2"
                            onClick={() => handleRemoteType('SA')}
                        >
                            <FlexRow justifyContent="space-between" gap="1.5">
                                <AppText color="#101828" fontWeight="600" cursor="pointer">Remote to Admin View</AppText>
                                <AdminIcon />
                            </FlexRow>
                        </GridContainer>
                        <GridContainer 
                            height="7.5" borderColor="#EAECF0" radius="0.8" bottomMargin="1.5" 
                            leftPadding="2" rightPadding="2"
                            onClick={() => !allInstitutions?.length ? handleRemoteType('institution') : null}
                        >
                            <FlexRow justifyContent="space-between" gap="1.5">
                                <AppText color="#101828" fontWeight="600" cursor="pointer">Remote to Other Institution</AppText>
                                <InstitutionIcon />
                            </FlexRow>
                        </GridContainer>
                    </CustomContainer>
                    { remoteType === 'institution' &&
                        <>
                            {/* <FormGroupWithIcon>
                                <label>Institution</label>
                                <div className="input-with-icon">
                                    <select 
                                        placeholder="Enter Code"
                                        value={institutionId}
                                        onChange={(e) => setInstitutionId(e.target.value)}
                                    >
                                        <option value="">Select Institution</option>
                                        {
                                            allInstitutions?.map((item: any, index: number) => {
                                                return (
                                                    <option value={item.id} key={index}>{item.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon> */}

                        <ReactSelect 
                            bottomMargin="1.5" topMargin="1"
                            styles={utility.reactSelectStyles()} 
                            options={
                                allInstitutions?.map((item: any, index: number) => {
                                    return {
                                       label: item.name,
                                       value: item.id,
                                       key: index
                                    }
                               })
                            } 
                            isClearable={true} 
                            isSearchable={true}
                            name='institution' 
                            placeholder="Select Institution" 
                            menuPortalTarget={document.body} 
                            value={institution}
                            onChange={(val) => setInstitution(val)}
                        />
                                
                            <FlexRow justifyContent='flex-end'>
                                <Button
                                    width='8.1'
                                    titleSize='1.4' fontWeight='600'
                                    height='4' radius='0.8'
                                    bottomMargin='1' hoverColor='#0D968F'
                                    hoverBgColor='#fff' borderColor='#0D968F'
                                    onClick={handleInstitutionRemote}
                                >
                                    Submit
                                </Button>
                            </FlexRow>
                        </>
                    }
                </CustomContainer>
            </FlexColumn>
        </>
    )
}