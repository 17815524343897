import utility, { catchErrMsg, erroMessage, loglite, requestMessage } from 'src/utils/utility'
import React, { useContext } from 'react'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import apiCaller from '../interceptor'
import { Feed, ForgotPasswordInput, IOnboardingFlowAndMigrationInfo, Login, ReportResponse, UserTypes } from 'src/model'
import helpers from 'src/utils/helpers'
import { downloadLimitMsg } from 'src/provider/config/constant'


const ApiContext = React.createContext<any>(null)

export const ApiProvider = (props: any) => {
    const {loader, notifier} = useContext(VisibilityContext)
    const {info, setInfoProperty, logout} = useContext(AppInfoContext)

    const institutionData = {
        ibInstitutionCode: '',
        terminalType: 0,
        id: info.institutionData?.id || 1,
        ...info.institutionData
    }

    async function login (payload: Login) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/Login`, {
                ...payload,
                ibInstitutionCode: payload.ibInstitutionCode ? Number(payload.ibInstitutionCode) : null,
                password: helpers.hashString(payload.password)
            })
            loader(false)

            loglite({data}, 'login API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                const info = {...data.data}
                if (info.role != 'SA') {
                    const institution = await getInstitutionById(Number(payload.ibInstitutionCode))
                    if (!institution) return false
                }
        
                await Promise.all([
                    setInfoProperty('role', info.role),
                    setInfoProperty('userType', info.role),
                    setInfoProperty('adminAuthToken', info.authToken),
                    setInfoProperty('sidebarMenuType', [UserTypes.ADMIN, UserTypes.USER].includes(info.role) ? 'appConfig' : 'institutions'),
                    setInfoProperty('userData', info)
                ])

                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }
   
    async function getTransferReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetTransfers?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getTransferReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getBillPaymentReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetBillPayments?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getBillPaymentReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAirtimeReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAirtimeTopups?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getAirtimeReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateDesign (design: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateInstitutionPage1`, {
                id: institutionData?.id,
                design
            })
            loader(false)

            loglite({data}, 'updateDesign API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Design Successfully Updated", null, 'success')
                setInfoProperty('institutionData', {...info.institutionData, ...data.data})
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateAppDetails (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateInstitutionPage2`, {
                id: institutionData?.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'updateAppDetails API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Application Details Successfully Updated", null, 'success')
                setInfoProperty('institutionData', {...info.institutionData, ...data.data})
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateConfiguration (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateInstitutionPage3`, {
                id: institutionData?.id,
                ...payload,
                maximumDailyTransactionLimit: Number(payload.maximumDailyTransactionLimit),
                maximumOneTimeTransactionLimit: Number(payload.maximumOneTimeTransactionLimit),
                defaultOneTimeTransactionLimit: Number(payload.defaultOneTimeTransactionLimit),
                defaultDailyTransactionLimit: Number(payload.defaultDailyTransactionLimit),
                twoFactorThresholdAmount: Number(payload.twoFactorThresholdAmount)
            })
            loader(false)

            loglite({data}, 'updateConfiguration API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Configuration Successfully Updated", null, 'success')
                setInfoProperty('institutionData', {...info.institutionData, ...data.data})
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateFeatureAccess (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateInstitutionPage4`, {
                id: institutionData?.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'updateFeatureAccess API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Feature Control Successfully Updated", null, 'success')
                setInfoProperty('institutionData', {...info.institutionData, ...data.data})
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getOnboardingSlides () {
        try {
            loader(true)
            const {data} = await apiCaller.get(`Admin/GetOnboardingSideInfo`, {
                params: {
                    IBInstitutionCode: institutionData?.id
                }
            })
            loader(false)

            loglite({data}, 'getOnboardingSlides API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return data.data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function addNewOnboardingSlide (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/addNewOnboardingSideInfo`, {...payload}, {
                params: {
                    IBInstitutionCode: institutionData?.id
                }
            })
            loader(false)

            loglite({data}, 'addNewOnboardingSlide API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Onboarding Slide Successfully Added", null, 'success')
                return true
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deleteOnboardingSlide (ID: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/DeleteOnboardingSideInfo`, {}, {
                params: {
                    IBInstitutionCode: institutionData?.id,
                    ID
                }
            })
            loader(false)

            loglite({data}, 'deleteOnboardingSlide API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Onboarding Slide Successfully deleted", null, 'success')
                return true
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getFeeds () {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetFeeds`, {IbInstitutionCode: institutionData?.id}, {
                params: {
                    pageNumber: 1,
                    perPage: 50
                }
            })
            loader(false)

            loglite({data}, 'GetFeeds API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return data.data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateFeed (payload: Feed) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateFeed`, {...payload}, {
                params: {
                    IbInstitutionCode: institutionData?.id
                }
            })
            loader(false)

            loglite({data}, 'updateFeed API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Feed Successfully Updated', null, 'success')
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function addFeed (payload: Feed) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/SaveFeed`, {...payload}, {
                params: {
                    IBInstitutionCode: institutionData?.id
                }
            })
            loader(false)

            loglite({data}, 'addFeed API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Feed Successfully Saved', null, 'success')
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function activateFeed (feedId: number, type: string) {
        let route = type === 'web' ? 'ActivateForWeb' : 'ActivateForMobile'
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/${route}`, null, {
                params: {
                    IBInstitutionCode: institutionData?.id,
                    feedId
                }
            })
            loader(false)

            loglite({data}, 'activateFeed API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Feed Successfully Activated', null, 'success')
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deactivateFeed (feedId: number, type: string) {
        let route = type === 'web' ? 'DeactivateForWeb' : 'DeactivateForMobile'
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/${route}`, null, {
                params: {
                    IBInstitutionCode: institutionData?.id,
                    feedId
                }
            })
            loader(false)

            loglite({data}, 'deactivateFeed API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Feed Successfully Deactivated', null, 'success')
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function deleteFeed (feedId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/DeleteFeed`, null, {
                params: {
                    IBInstitutionCode: institutionData?.id,
                    feedId
                }
            })
            loader(false)

            loglite({data}, 'deleteFeed API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Feed Successfully Deleted', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getProfileReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetProfiles?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getProfileReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function getLoginReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetLoginHistory?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getLoginReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getFailedTxReport (pageNumber: number, pageSize: number, payload: any = {}) {
        payload = {...payload, transactionStartDate: payload?.startDateTime, transactionEndDate: payload?.endDateTime}
        delete payload.startDateTime
        delete payload.endDateTime

        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetFailedTransactionLogs?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload,
                ...(payload.amount ? {amount: Number(payload.amount)} : {})
            })
            loader(false)

            loglite({data}, 'getFailedTxReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getFixedDepositReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetFixedDeposits?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getFixedDepositReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getTargetSavingsReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetTargetSavings?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getTargetSavingsReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getTxLimitReport (pageNumber: number, pageSize: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAccountTransactionLimit?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getTxLimitReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAuditReport (pageNumber: number, perPage: number, payload = {}, isAll = false) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAuditLogs?pageNumber=${pageNumber}&perPage=${perPage}`, {
                ...(isAll ? {} : {ibInstitutionCode: institutionData.id}),
                ...payload
            })
            loader(false)

            loglite({data}, 'getAuditReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAdminLoginReport (pageNumber: number, perPage: number, payload = {}, isAll = false) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAdminUserLoginHistory?pageNumber=${pageNumber}&perPage=${perPage}`, {
                ...(isAll ? {} : {ibInstitutionCode: institutionData.id}),
                ...payload
            })
            loader(false)

            loglite({data}, 'getAdminLoginReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getApplicationFeatures () {
        try {
            loader(true)
            const {data} = await apiCaller.get(`Admin/GetApplicationFeatures`)
            loader(false)

            loglite({data}, 'getApplicationFeatures API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateFeatureSubscription (featureList: string) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateInstitutionFeatureSubscription`, null, {
                params: {
                    IBInstitutionCode: institutionData?.id,
                    featureList
                }
            })
            loader(false)

            loglite({data}, 'updateFeatureSubscription API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                await getInstitutionById(institutionData?.id)
                notifier.show(data.message || 'Configured Features Successfully Updated', null, 'success')
                return data.data
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getInstitutionByAlias (alias: string) {
        try {
            loader(true)
            const {data} = await apiCaller.get(`Institution/api/1.1/institution/GetInstitutionByAlias?alias=${alias || info.institutionData?.alias}`)
            loader(false)

            loglite({data}, 'getInstitutionByAlias API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                const institutionInfo = {...data.data?.institution, featureIDs: data.data?.featureIDs}
                await setInfoProperty('institutionData', institutionInfo)
                return institutionInfo
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function getInstitutionById (id: number, isLoggedInUser = true) {
        if (!id) return false

        try {
            loader(true)
            const {data} = await apiCaller.get(`Institution/api/1.1/Institution/GetInstitutionByID?id=${id}`)
            loader(false)

            loglite({data}, 'getInstitutionById API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                const institutionInfo = {
                    ...data.data?.institution, 
                    featureIDs: data.data?.featureIDs, 
                    migrationInfo: data.data?.migrationInfo,
                    recovaCredential: data.data?.recovaCredential,
                    ebanqoCredential: data.data?.ebanqoCredential
                }
                if (isLoggedInUser) {
                    await setInfoProperty('institutionData', institutionInfo)
                }
                return institutionInfo
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAllInstitutions () {
        try {
            loader(true)
            const {data} = await apiCaller.get(`Admin/GetAllInstitution`)
            loader(false)

            loglite({data}, 'getAllInstitutions API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data.data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function lockUser (profileId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/LockProfile?IBInstitutionCode=${institutionData?.id}&profileId=${profileId}`)
            loader(false)

            loglite({data}, 'lockUser API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function unlockUser (profileId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UnlockProfile?IBInstitutionCode=${institutionData?.id}&profileId=${profileId}`)
            loader(false)

            loglite({data}, 'unlockUser API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function restoreProfile (profileId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/RestoreProfile?IBInstitutionCode=${institutionData?.id}&profileId=${profileId}`)
            loader(false)

            loglite({data}, 'restoreProfile API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createSuperAdmin (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/CreateSuperAdmin`, payload)
            loader(false)

            loglite({data}, 'createSuperAdmin API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createInstitutionAndAdmin (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/CreateInstitutionAndAdmin`, payload)
            loader(false)

            loglite({data}, 'createInstitutionAndAdmin API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Institution and Admin created successfully.', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function createInstitutionUser (payload: any) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/CreateInstitutionUser`, {
                ibInstitutionCode: institutionData?.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'createInstitutionUser API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return true
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAllAdminUsers (pageNumber: number, perPage: number, payload: any = {}) {
        payload = {...payload, ibInstitutionCode: payload?.institutionCode}
        delete payload.institutionCode
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAllAdminUsers?pageNumber=${pageNumber}&perPage=${perPage}`, {...payload})
            loader(false)

            loglite({data}, 'getAllAdminUsers API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAdminUsers (pageNumber: number, perPage: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetAdminUsers?pageNumber=${pageNumber}&perPage=${perPage}`, {
                ibInstitutionCode: institutionData.id
            })
            loader(false)

            loglite({data}, 'getAdminUsers API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getInstitutionsReport (pageNumber: number, perPage: number, payload = {}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetInstitutions?pageNumber=${pageNumber}&perPage=${perPage}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getInstitutionsReport API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function updateOnboardingFlowAndMigrationInfo (payload: IOnboardingFlowAndMigrationInfo) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/SaveOnboardingAndMigrationInfo`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'updateOnboardingFlowAndMigrationInfo API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Information Successfully Updated", null, 'success')
                return true
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function changeAdminUserPassword (payload: {oldPassword: string, newPassword: string}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/ChangeAdminUserPassword`, {
                oldPassword: helpers.hashString(payload.oldPassword),
                newPassword: helpers.hashString(payload.newPassword)
            })
            loader(false)

            loglite({data}, 'changeAdminUserPassword API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || "Password Successfully Changed", null, 'success')
                return true
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function downloadReport (report: ReportResponse, reportName: string) {
        const columns = utility.reportColumns(reportName)
        let data = []
        
        if (columns.length) {
            for (let item of report.data) {
                const rowData: any = {}
                for (let column of columns) {
                    rowData[column] = item[column]
                }
                data.push(rowData)
            }
        }
        else data = report.data
        
        utility.downloadReportCSV(data, reportName)

        if (report.pageCount > 1) notifier.show(downloadLimitMsg, null, 'success')
    }

    async function sendForgotPasswordOtp (ibInstitutionCode: string|number, email: string) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/SendForgotPasswordOtp`, {
                ibInstitutionCode, 
                email
            })
            loader(false)

            loglite({data}, 'sendForgotPasswordOtp API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function validateForgotPasswordOtp (ibInstitutionCode: string|number, email: string, otp: string) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/ValidateForgotPasswordOtp`, {
                ibInstitutionCode, 
                email,
                otp: helpers.hashString(otp)
            })
            loader(false)

            loglite({data}, 'validateForgotPasswordOtp API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function resetAdminUserPassword (payload: ForgotPasswordInput) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/ResetAdminUserPassword`, {
                ...payload,
                otp: helpers.hashString(payload.otp),
                newPassword:helpers.hashString(payload.newPassword),
                confirmPassword: helpers.hashString(payload.confirmPassword)
            })
            loader(false)

            loglite({data}, 'resetAdminUserPassword API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Password Successfully Reset', null, 'success')
                return true
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function repushEmail (ibInstitutionCode: number, userId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/RepushAdminEmail`, {}, {
                params: {
                    ibInstitutionCode,
                    userId
                }
            })
            loader(false)

            loglite({data}, 'repushEmail API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Email Successfully Resent', null, 'success')
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function resetUserProfile (profileId: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/ResetProfile`, {}, {
                params: {
                    iBInstitutionCode: institutionData?.id,
                    profileId
                }
            })
            loader(false)

            loglite({data}, 'resetUserProfile API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Profile Successfully Reset', null, 'success')
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getNotifications (pageNumber: number) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetNotifications`, {
                iBInstitutionCode: institutionData?.id,
                isGlobal: true
            }, {
                params: {
                    pageNumber,
                    perPage: 10
                }
            })
            loader(false)

            loglite({data}, 'getNotifications API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function sendNotification (payload: {title: string, message: string}) {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/sendNotification`, {
                iBInstitutionCode: institutionData?.id,
                isGlobal: true,
                ...payload
            })
            loader(false)

            loglite({data}, 'sendNotification API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Notification Successfully Sent', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function fetchAndroidBuildStatus () {
        try {
            loader(true)
            const {data} = await apiCaller.get(`Admin/GetAndroidAppBuildStatus`, {
                params: {
                    iBInstitutionCode: institutionData?.id
                }
            })
            loader(false)

            loglite({data}, 'fetchAndroidBuildStatus API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else return data.data
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function StartAndroidBuild () {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/StartNewAndroidBuild`, {
                ibInstitutionCode: institutionData?.id,
                buildType: 'apk'
            })
            loader(false)

            loglite({data}, 'StartAndroidBuild API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Successfully Started Build', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    async function updateBillsPayment () {
        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/UpdateBillsPayment`)
            loader(false)

            loglite({data}, 'updateBillsPayment API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                notifier.show(data.message || 'Successfully Updated Bills Payment', null, 'success')
                return true
            }
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }

    async function getAccountUpgradeRequests (pageNumber: number, pageSize: number, payload: any = {}) {
        payload = {...payload, requestedAt: payload?.startDateTime, requestedAtEndTime: payload?.endDateTime}
        delete payload.startDateTime
        delete payload.endDateTime

        try {
            loader(true)
            const {data} = await apiCaller.post(`Admin/GetUpgradeAccountRecords?pageNumber=${pageNumber}&perPage=${pageSize}`, {
                ibInstitutionCode: institutionData.id,
                ...payload
            })
            loader(false)

            loglite({data}, 'getAccountUpgradeRequests API')
            if (!data.isSuccessful) {
                notifier.show(requestMessage(data))
                return false
            }
            else {
                return data
            } 
        }
        catch (err) {
            loader(false)
            notifier.show(erroMessage(catchErrMsg(err)))
            return null
        }
    }


    const callActions = {
        login,
        getTransferReport,
        getBillPaymentReport,
        getAirtimeReport,
        updateDesign,
        updateAppDetails,
        updateConfiguration,
        updateFeatureAccess,
        getOnboardingSlides,
        addNewOnboardingSlide,
        deleteOnboardingSlide,
        getFeeds,
        updateFeed,
        addFeed,
        activateFeed,
        deactivateFeed,
        getProfileReport,
        getLoginReport,
        getFailedTxReport,
        getFixedDepositReport,
        getTargetSavingsReport,
        getApplicationFeatures,
        getInstitutionByAlias,
        getInstitutionById,
        getTxLimitReport,
        updateFeatureSubscription,
        getAuditReport,
        getAdminLoginReport,
        deleteFeed,
        getAllInstitutions,
        lockUser,
        unlockUser,
        restoreProfile,
        createSuperAdmin,
        createInstitutionAndAdmin,
        createInstitutionUser,
        getAllAdminUsers,
        getAdminUsers,
        getInstitutionsReport,
        updateOnboardingFlowAndMigrationInfo,
        changeAdminUserPassword,
        sendForgotPasswordOtp,
        validateForgotPasswordOtp,
        resetAdminUserPassword,
        repushEmail,
        resetUserProfile,
        getNotifications,
        sendNotification,
        fetchAndroidBuildStatus,
        StartAndroidBuild,
        updateBillsPayment,
        getAccountUpgradeRequests
    }

    return (
        <ApiContext.Provider value={{API: callActions, downloadReport}} >
            {props.children}
        </ApiContext.Provider>
    )
}

export default ApiContext