import './style.scss'
import { useContext, useEffect, useState } from 'react';
import utility from 'src/utils/utility'
import { AbsoluteContainer, AppSpan, AppText, Button, CustomContainer, FlexRow, GridContainer, Input, ScreenContainer } from 'src/style';
import VisibilityContext from 'src/provider/state-manager/visibilityProvider';
import ApiContext from 'src/provider/API/call-service';
import AppInfoContext from 'src/provider/state-manager/appInfoProvider';
import { ThirdAppDesign } from 'src/component';
import { AddNewFeed } from 'src/popup';
import MBankingFeedImg from 'src/assets/img/mbanking-feed.png'
import IbankingFeedView from 'src/assets/img/ibanking-feed.png'
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { FiSave } from 'react-icons/fi';
import { Feed, UserTypes } from 'src/model';
import {ReactComponent as NoDataIcon} from 'src/assets/img/no-data-icon.svg'
import helpers from 'src/utils/helpers';


const IBankingFeed = () => {
    const {notifier} = useContext(VisibilityContext);
    const {API} = useContext(ApiContext)
    const {info: {userType}} = useContext(AppInfoContext)
    const [feeds, setFeeds] = useState<Array<Feed>>([])
    const [link, setLink] = useState<any>({})
    const [userRole, setUserRole] = useState('')
    const [showAddNewAds, setShowAddNewAds] = useState(false)

    useEffect(() => {
        setUserRole(userType)
    }, [userType])

    useEffect(() => {
        handleFetchFeeds()
    }, [])

    async function handleFetchFeeds() {
        let response = await API.getFeeds()
        if (response) setFeeds(response)
    }

    async function handleUpdateFeed(id: number) {
        if (!link[id]) return notifier.show('Kindly provide the link url to update')
        
        const response = await API.updateFeed({linkUrl: link[id], id})
        if (response) handleFetchFeeds()
    }

    async function handleActivate_DeactivateFeed (type: string, feedId: number) {
        let response
        if (type === 'activate') response = await API.activateFeed(feedId, 'web')
        else response = await API.deactivateFeed(feedId, 'web')

        if (response) handleFetchFeeds()
    }

    async function handleDeleteFeed (feedId: number) {
        const response = await API.deleteFeed(feedId)
        if (response) handleFetchFeeds()
    }

    return (
        <>
            <ScreenContainer>
                <GridContainer topMargin='2' >
                    <CustomContainer width='75' className='ibanking-main-container'>
                        <FlexRow justifyContent='space-between' alignItems='flex-start' className='ads-title-wrapper'>
                            <AppText textSize='1.9' color='#101828' fontWeight="700">
                                Internet Banking Feeds
                            </AppText>
                            { userRole === UserTypes.ADMIN &&
                                <Button
                                    width='16' leftMargin='2'
                                    titleSize='1.4' fontWeight='600'
                                    height='4' radius='0.8' hoverColor='#0D968F'
                                    hoverBgColor='#fff' borderColor='#0D968F'
                                    onClick={() => setShowAddNewAds(true)}
                                >
                                    New Advertisement
                                </Button>
                            }
                        </FlexRow>
                        { helpers.checkFeedAvailablity(feeds, 'web') ?
                            <>
                                {
                                    feeds?.map((feed, index) => {
                                        return (
                                            feed.webImageUrl &&
                                                <CustomContainer topMargin='1' bottomMargin='2' key={index}>
                                                    <CustomContainer topMargin='2'>
                                                        {/* { feed?.webImageUrl ?
                                                            <CustomContainer maxHeight='14' radius='1.29' height='14'>
                                                                <img src={feed.webImageUrl || feed.mobileImageUrl || MBankingFeedImg} className='ads-img' />
                                                            </CustomContainer>
                                                            :
                                                            <AppText>No Image URL</AppText>
                                                        } */}
                                                        <CustomContainer maxHeight='14' radius='1.29' height='14'>
                                                            <img src={feed.webImageUrl} className='ads-img' />
                                                        </CustomContainer>
                                                        <CustomContainer topMargin='2'>
                                                            <AppSpan color='#344054' fontWeight='600'>Feed Link</AppSpan>
                                                            <GridContainer 
                                                                height='4' borderColor='#D0D5DD' justifyContent='space-between'
                                                                radius='0.8' alignItems='center' topMargin='0.6'
                                                                leftPadding='1' rightPadding='0.7'
                                                            >   
                                                                <FlexRow justifyContent='flex-start'>
                                                                    <CustomContainer 
                                                                        rightPadding='2' height='4'
                                                                        className='feed-link-input'
                                                                    >
                                                                        <Input
                                                                            textSize='1.6' height='4' bottomPadding='0.5'
                                                                            onChange={(e) => setLink({...link, [feed.id]: e.target.value})}
                                                                            value={link[feed.id] || feed.linkUrl}
                                                                            style={{wordWrap: 'normal', color: '#667085'}}
                                                                            disabled={userRole != UserTypes.ADMIN}
                                                                        />
                                                                        <AbsoluteContainer top='0.3' right='1' zIndex='4' className='key-explainer'>
                                                                            <AppSpan color='#98A2B3' textSize='1.8' cursor='pointer'>
                                                                                <AiOutlineQuestionCircle />
                                                                            </AppSpan>
                                                                        </AbsoluteContainer>
                                                                    </CustomContainer>
                                                                    <FlexRow 
                                                                        width='11' height='4'
                                                                        style={{borderLeft: '1px solid #D0D5DD'}}
                                                                        onClick={() => userRole === UserTypes.ADMIN && handleUpdateFeed(feed.id)}
                                                                    >
                                                                        <AppSpan textSize='2' bottomPadding='0.8'>
                                                                            <FiSave />
                                                                        </AppSpan>
                                                                        <AppText leftMargin='0.8' fontWeight='600' cursor='pointer'>Update</AppText>
                                                                    </FlexRow>
                                                                </FlexRow>
                                                            </GridContainer>
                                                        </CustomContainer>
                                                        { userRole === UserTypes.ADMIN &&
                                                            <FlexRow justifyContent='flex-end' alignItems='flex-start' topMargin='2' gap='2'>
                                                                <Button
                                                                    width='14' color='#344054'
                                                                    titleSize='1.4' fontWeight='600' bgColor='#ffffff'
                                                                    height='4' radius='0.8' hoverColor='#0D968F'
                                                                    hoverBgColor='#fff' borderColor='#D0D5DD'
                                                                    onClick={() => handleDeleteFeed(feed.id)}
                                                                >
                                                                    Delete Feed
                                                                </Button>
                                                                <Button
                                                                    width='15'
                                                                    titleSize='1.4' fontWeight='600'
                                                                    height='4' radius='0.8' hoverColor='#0D968F'
                                                                    hoverBgColor='#fff' borderColor='#0D968F'
                                                                    onClick={() => handleActivate_DeactivateFeed((feed.isWebActive) ? 'deactivate' : 'activate', feed.id)}
                                                                >
                                                                    {(feed.isWebActive) ? 'Deactivate Feed' : 'Activate Feed'}
                                                                </Button> 
                                                            </FlexRow>
                                                        }
                                                    </CustomContainer>
                                                </CustomContainer>
                                        )
                                    })
                                }
                                <GridContainer 
                                    width='100' sizeUnit='%' topMargin='4' height='35.8'
                                    className='mbanking-phone-hand'
                                >
                                    <img src={IbankingFeedView} className='ads-img' />
                                </GridContainer>
                            </>
                            : 
                            <GridContainer 
                                minHeight='28' padding='2' radius='0.8'
                                borderColor='#D0D5DD' topMargin='2'
                            >
                                <CustomContainer width='auto' sizeUnit=''>
                                    <NoDataIcon className='icon-component' />
                                    <AppText align='center' color='#101828' textSize='1.6' fontWeight='600'>No Active Feed</AppText>
                                </CustomContainer>
                            </GridContainer>
                        }
                    </CustomContainer>
                </GridContainer>
            </ScreenContainer>
            { showAddNewAds &&
                <AddNewFeed
                    getUpdatedFeeds={handleFetchFeeds}
                    isWeb={true}
                    close={() => setShowAddNewAds(false)}
                />
            }
        </>
    )
}

export default utility.routeData('ibanking-feed', 'Internet Banking Feed', <IBankingFeed/>)