let isLive = process.env.REACT_APP_ENVIRONMENT === 'production';

export const url = {
    BASE_URL: isLive ? 'https://api.qoreonline.com/api' : 'https://stagingapi.qore.build/api'
}

export const appSettings = {
    SESSION_EXPIRATION_LENGTH: 3,
    INACTIVITY_TIME: 1000 * 60 * 3
}

export const downloadLimitMsg = `The report you have requested exceeds the maximum of 2,000 records.
Please view the report and adjust the filter to view any missing records you might need`

const mfbRoutesData = [
    {
        title: 'Theme Design',
        path: '/dashboard/app-design',
        menuCategory: 'appConfig'
    },
    {
        title: 'Application Details',
        path: '/dashboard/app-details',
        menuCategory: 'appConfig'
    },
    {
        title: 'Onboarding Slides',
        path: '/dashboard/onboarding-slides',
        menuCategory: 'appConfig'
    },
    {
        title: 'Configuration',
        path: '/dashboard/configuration',
        menuCategory: 'appConfig'
    },
    {
        title: 'Feature Control',
        path: '/dashboard/feature-control',
        menuCategory: 'appConfig'
    },
    {
        title: 'App Generation',
        path: '/dashboard/app-generation',
        menuCategory: 'appConfig'
    },
    {
        title: 'Web Banking',
        path: '/dashboard/ibanking-feed',
        menuCategory: 'feedManagement'
    },
    {
        title: 'Mobile Banking',
        path: '/dashboard/mbanking-feed',
        menuCategory: 'feedManagement'
    },
    {
        title: 'Notification',
        path: '/dashboard/notification',
        menuCategory: 'feedManagement'
    },
    {
        title: 'Customer Profile',
        path: '/dashboard/customer-profile-report',
        menuCategory: 'reports'
    },
    {
        title: 'Customer Login',
        path: '/dashboard/customer-login-report',
        menuCategory: 'reports'
    },
    {
        title: 'Failed Transactions',
        path: '/dashboard/failed-tx-report',
        menuCategory: 'reports'
    },
    {
        title: 'Transfer',
        path: '/dashboard/transfer-report',
        menuCategory: 'reports'
    },
    {
        title: 'Bills payment',
        path: '/dashboard/bill-payment-report',
        menuCategory: 'reports'
    },
    {
        title: 'Airtime',
        path: '/dashboard/airtime-report',
        menuCategory: 'reports'
    },
    {
        title: 'Transaction Limit',
        path: '/dashboard/tx-limit-report',
        menuCategory: 'reports'
    },
    {
        title: 'Fixed Deposit',
        path: '/dashboard/fixed-deposit-report',
        menuCategory: 'reports'
    },
    {
        title: 'Cardless Withrawals',
        path: '/dashboard/cardless-withdrawal-report',
        menuCategory: 'reports'
    },
    {
        title: 'Loans',
        path: '/dashboard/loans-report',
        menuCategory: 'reports'
    },
    {
        title: 'Target Savings',
        path: '/dashboard/target-savings-report',
        menuCategory: 'reports'
    },
    {
        title: 'Audit Reports',
        path: '/dashboard/audit-report',
        menuCategory: 'audit'
    },
    {
        title: 'Login Reports',
        path: '/dashboard/login-report',
        menuCategory: 'audit'
    },
    {
        title: 'Users',
        path: '/dashboard/users',
        menuCategory: 'users'
    },
    {
        title: 'User Profile',
        path: '/dashboard/user-profile',
        menuCategory: ''
    },
    {
        title: 'Audit Report Details',
        path: '/dashboard/audit-report/details',
        menuCategory: 'audit'
    },
    {
        title: 'Account Upgrade',
        path: '/dashboard/account-upgrade-requests',
        menuCategory: 'reports'
    }
]

export const dashboardRoutesData = {
    Admin: mfbRoutesData,
    User: mfbRoutesData,
    SA: [
        {
            title: 'Institutions',
            path: '/dashboard/institutions',
            menuCategory: 'institutions'
        },
        {
            title: 'Audit Reports',
            path: '/dashboard/audit-report',
            menuCategory: 'audit'
        },
        {
            title: 'Login Reports',
            path: '/dashboard/login-report',
            menuCategory: 'audit'
        },
        {
            title: 'Institutions',
            path: '/dashboard/institution-details',
            menuCategory: 'institutions'
        },
        {
            title: 'Users',
            path: '/dashboard/users',
            menuCategory: 'users'
        },
        {
            title: 'User Profile',
            path: '/dashboard/user-profile',
            menuCategory: ''
        },
        {
            title: 'Audit Report Details',
            path: '/dashboard/audit-report/details',
            menuCategory: 'audit'
        }
    ]
} 

export const menuCategory = {
    appConfig: [
        '/dashboard/app-design',
        '/dashboard/app-details',
        '/dashboard/onboarding-slides',
        '/dashboard/configuration',
        '/dashboard/feature-control',
        '/dashboard/app-generation'
    ],
    feedManagement: [
        '/dashboard/ibanking-feed',
        '/dashboard/mbanking-feed',
        '/dashboard/notification'
    ],
    reports: [
        '/dashboard/customer-profile-report',
        '/dashboard/customer-login-report',
        '/dashboard/failed-tx-report',
        '/dashboard/transfer-report',
        '/dashboard/bill-payment-report',
        '/dashboard/airtime-report',
        '/dashboard/tx-limit-report',
        '/dashboard/fixed-deposit-report',
        '/dashboard/cardless-withdrawal-report',
        '/dashboard/loans-report',
        '/dashboard/target-savings-report',
        '/dashboard/user-profile',
        '/dashboard/account-upgrade-requests'
    ],
    audit: [
        '/dashboard/audit-report',
        '/dashboard/login-report',
        '/dashboard/audit-report/details'
    ]
}