import './style.scss'
import { useState, useContext, useEffect, useRef } from 'react';
import ApiContext from 'src/provider/API/call-service';
import utility from 'src/utils/utility'
import { CustomContainer, ScreenContainer } from 'src/style';
import { ReportFilter, ServerSideTable } from 'src/component';
import helpers from 'src/utils/helpers';
import { accountUpgradeRequestColumn, accountUpgradeRequestData } from 'src/utils/tableLayout';


const AccountUpgradeRequests = () => {
    const {API, downloadReport} = useContext(ApiContext)
    const [requestsData, setRequestsData] = useState<any>(null)
    const [requests, setRequests] = useState<any>(null)
    const [showFilter, setShowFilter] = useState<boolean>(false)
    const [resetPagination, setResetPagination] = useState<boolean>(false)

    const filterDataRef = useRef({})
    
    useEffect(() => {
        filterDataRef.current = {}
        handleFetchRequests()
    }, [])

    async function handleFetchRequests (pageNumber = 1, pageSize = 10, isDownload = false) {
        const response = await API.getAccountUpgradeRequests(pageNumber, pageSize, {...filterDataRef.current, returnMax: isDownload})
        if (response) {
            if (isDownload) {
                return downloadReport(response, 'ACCOUNT_UPGRADE_REQUESTS')
            }
            setRequestsData(response)
            setRequests(helpers.addSerialNumberToItems(response?.data, {pageNumber, pageSize}))
        }
    }

    function handleClear () {
        filterDataRef.current = {}
        handleFetchRequests()
        setResetPagination(!resetPagination)
    }

    function handleViewDetails (data: any) {

    }

    return (
        <>
            <ScreenContainer>
                <CustomContainer topMargin='3'>
                    <ServerSideTable
                        tableData={requests}
                        tableColumns={accountUpgradeRequestColumn(handleViewDetails)} 
                        dataFetchMethod={handleFetchRequests}
                        totalRows={requestsData?.total}
                        tableTitle='Account Upgrade Requests'
                        customStyle
                        shouldResetPagination={resetPagination}
                        //canSearch
                        canFilter={() => setShowFilter(true)}
                        canClear={handleClear}
                        downloadAction={() => handleFetchRequests(1, 10, true)}
                    />
                </CustomContainer>
            </ScreenContainer>
            { showFilter &&
                <ReportFilter
                    type='account-upgarde'
                    onApplyFilter={handleFetchRequests}
                    setFilterData={(data: any) => {filterDataRef.current = data}}
                    close={() => setShowFilter(false)} 
                />
            }
        </>
    )
}

export default utility.routeData('account-upgrade-requests', 'Account Upgrade Requests', <AccountUpgradeRequests/>)