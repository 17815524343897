import './style.scss'
import { useState, useContext, useEffect } from 'react';
import utility from 'src/utils/utility'
import ApiContext from 'src/provider/API/call-service';
import { 
    AppLabel, AppSpan, AppText, Button, 
    CustomContainer, FlexColumn, 
    GridContainer, ScreenContainer 
} from 'src/style';
import ApkDownloadIcon from 'src/assets/img/apk-download.svg'
import { IAndroidBuild } from 'src/model';
import helpers from 'src/utils/helpers';



const AppGeneration = () => {
    const {API} = useContext(ApiContext)
    const [buildStatusInfo, setBuildStatusInfo] = useState<IAndroidBuild|null>(null)

    useEffect(() => {
        handleFetchBuildStatus()
    }, [])

    async function handleFetchBuildStatus () {
        const response = await API.fetchAndroidBuildStatus() 
        if (response) {
            setBuildStatusInfo(response)
        }
    }

    async function handleStartNewBuild () {
        const response = await API.StartAndroidBuild()
        if (response) handleFetchBuildStatus()
    }

    function handleDownload () {
        const downloadLink = document.createElement('a');
        document.body.appendChild(downloadLink);
        downloadLink.download = 'Mobile_Banking';
        downloadLink.href = buildStatusInfo?.lastApkBuildUrl!;
        downloadLink.click();
    }

    return (
        <ScreenContainer>
            <AppText textSize='1.9' color='#101828' fontWeight="700">
                APK Generation
            </AppText>
            
            <CustomContainer 
                width='50' sizeUnit='%' topMargin='2'
                className='apk-type-block'
            >
                { !buildStatusInfo?.hasPendingBuild ?
                    <Button
                        width='100' sizeUnit='%'
                        titleSize='1.4' fontWeight='600'
                        height='4' radius='0.8'
                        bottomMargin='2.5' hoverColor='#0D968F'
                        hoverBgColor='#fff' borderColor='#0D968F'
                        onClick={handleStartNewBuild}
                    >
                        Start APK Build
                    </Button>
                    :
                    <FlexColumn
                        minHeight='4.7' padding='1.5'
                        radius='0.8' bgColor='#E7F6F5'
                        bottomMargin='2'
                        shadow='box-shadow: 0px 1px 2px 0px #1018280D'
                    >
                        <AppText color='#0A756F' textSize='1.2'>
                            A build is currently in progress, you will receive an email as soon as it is completed.
                        </AppText>
                    </FlexColumn>
                }
                { buildStatusInfo?.lastApkBuildUrl &&
                    <GridContainer 
                        borderColor='#EAECF0' radius='0.8'
                        padding='2' minHeight='13'
                    >
                        <img src={ApkDownloadIcon} />
                        <AppText 
                            fontFamily='Montserrat' topMargin='1'
                            color='#667085' fontWeight='600'
                            align='center'
                        >
                            Generated on {buildStatusInfo?.lastBuildDateTime ? helpers.handleDate(buildStatusInfo?.lastBuildDateTime) : null}
                        </AppText>
                        <AppLabel 
                            color='#0A756F'
                            textSize='1.4' fontWeight='600' 
                            topMargin='1' hoverColor='#667085'
                            onClick={handleDownload}
                        >
                            Click to Download
                        </AppLabel>
                    </GridContainer>
                }
            </CustomContainer>
        </ScreenContainer>
    )
}

export default utility.routeData('app-generation', 'App Generation', <AppGeneration/>)