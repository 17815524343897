import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import VisibilityContext from 'src/provider/state-manager/visibilityProvider'
import AppInfoContext from 'src/provider/state-manager/appInfoProvider'
import { FlexRow, SideBarContainer, SideBarMenuWrapper, SideBarShadow } from "src/style"
import { sideBarData } from './sidebarData'
import { SidebarMenu } from './sidebarMenu'
import { Logo } from "src/assets/icons"


export const Sidebar: React.FC = () => {
    const navigate = useNavigate()
    const {visibility, showSideBar} = useContext(VisibilityContext)
    const {info: {sidebarMenuType}} = useContext(AppInfoContext)
    const [menuCategory, setMenuCategory] = useState(sidebarMenuType)

    useEffect(() => {
        if (sidebarMenuType) setMenuCategory(sidebarMenuType)
    }, [sidebarMenuType])

    return (
        <>
            <SideBarShadow isSideBar={visibility.isSideBar} onClick={() => showSideBar(false)}/>
            <SideBarContainer isSideBar={visibility.isSideBar}>
                <FlexRow justifyContent="flex-end" className='menu-wrapper'  >
                    <SideBarMenuWrapper>
                        <Logo className="header-logo sidebar-logo" />
                        {
                            sideBarData.map((menu, index) => {
                                return (menu.menuCategory === menuCategory ? <SidebarMenu item={menu} key={index} /> : null)
                            })
                        }
                    </SideBarMenuWrapper>
                </FlexRow>
            </SideBarContainer>
        </>
    )
}