import './style.scss'
import { useState } from 'react'
import { 
    AbsoluteContainer,
    Button,
   CustomContainer, FlexRow, Form, FormGroup, FormGroupWithIcon, SidePopupContainer 
} from "src/style"
import helpers from 'src/utils/helpers'
import { FiChevronDown } from 'react-icons/fi'
import { handleTypeNumberControl } from 'src/utils/utility'

export const ReportFilter: React.FC<any> = ({type, setFilterData, onApplyFilter, top, close}) => {
    const [input, setInput] = useState<any>({
        startDateTime: null, endDateTime: null, customerName: null, customerId: null, 
        category: null, accountNumber: null, network: null, firstName: null, lastName: null,
        username: null, isMobile: null, isSuccessful: null, amount: null, profileID: null,
        model: null, role: null, institutionCode: null, name: null, alias: null,
        onboardingMethod: null, status: null
    })

    let hasCustomerName = ['transfer', 'airtime', 'bill-payment', 'fixed-deposit', 'target-savings']
    let hasCustomerId = ['transfer', 'airtime', 'bill-payment', 'customer-profile', 'fixed-deposit', 'target-savings', 'audit-report', 'account-upgarde']
    let hasAccountNumber = ['transfer', 'customer-profile', 'failed-transactions', 'transaction-limit', 'account-upgarde']
    let hasAmount = ['failed-transactions']
    let hasProfileID = ['failed-transactions', 'transaction-limit']
    let hasFirstAndLastName = ['customer-profile', 'users']
    let hasUsername = ['customer-login', 'login-report', 'users']
    let noDateRange = ['']
    let hasInstitutionCode = ['institutions', 'users']
    let hasStatus = ['account-upgarde']


    const handleInput = (e: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>) => {
        setInput({...input, [e.target.name]: e.target.value})
    }

    async function handleSubmit(e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) {
        e.preventDefault()
        const isMobile = input.isMobile == 'Mobile' ? true : input.isMobile == 'Web' ? false : null
        const isSuccessful = input.isSuccessful == 'Success' ? true : input.isSuccessful == 'Failed' ? false : null

        await setFilterData({...input, isMobile, isSuccessful}) 
        onApplyFilter()
        close()
    }
    
    return (
        <>
            <SidePopupContainer zIndex="7" onClick={close} bgColor='rgba(0,0,0, 0.06)' />
            <AbsoluteContainer 
                zIndex="8" 
                top={top || '34'} 
                right='11' 
                className={top ? "report-filter-container-plus" : "report-filter-container"}
            >
                <CustomContainer
                    width="36" 
                    //minHeight={type === 'fixed-deposit' ? "38" : "43"} 
                    bgColor="#ffffff"
                    padding="2" radius="1"
                    shadow="0px 4px 10px 0px #0000000D"
                    className='report-content-container'
                >
                    <Form onSubmit={handleSubmit}>
                        { !noDateRange.includes(type) &&
                            <FlexRow 
                                justifyContent='space-between' gap='4' sizeUnit='%'
                                className='report-input-row'
                            >
                                <FormGroup bottomMargin='0' height='4'>
                                    <label>Start Date</label>
                                    <input 
                                        name='startDateTime'
                                        type='date'
                                        max={helpers.getInputValidDate()}
                                        style={{color: input.startDateTime ? '#000000' : '#667085'}}
                                        value={input.startDateTime}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                                <FormGroup bottomMargin='0'  height='4'>
                                    <label>End Date</label>
                                    <input 
                                        name='endDateTime'
                                        type='date'
                                        max={helpers.getInputValidDate()}
                                        style={{color: input.endDateTime ? '#000000' : '#667085'}}
                                        value={input.endDateTime}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup>
                            </FlexRow>
                        }
                        { hasCustomerName.includes(type) &&
                            <FormGroup height='4'>
                                <label>Customer name</label>
                                <input 
                                    placeholder='Enter name'
                                    name='customerName'
                                    value={input.customerName}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        }
                        { hasCustomerId.includes(type) &&
                            <FormGroup height='4'>
                                <label>Customer ID</label>
                                <input 
                                    placeholder='Enter customer ID'
                                    name='customerId'
                                    value={input.customerId}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup>
                        }
                        { type === 'bill-payment' &&
                            <FormGroupWithIcon >
                                <label>Category</label>
                                <div className="input-with-icon" style={{height: '4rem'}}>
                                    <select 
                                        placeholder="Select category"
                                        name='category'
                                        value={input.category}
                                        style={{color: input.category ? '#000000' : '#667085'}}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    >
                                        <option value="">Select category</option>
                                        <option value="Admin">Admin</option>
                                        <option value="User">User</option>
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        }
                        { hasAccountNumber.includes(type) &&
                            <FormGroup height='4'>
                                <label>Account number</label>
                                <input 
                                    placeholder='Enter account number'
                                    name='accountNumber'
                                    value={input.accountNumber}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup>   
                        }
                        { type === 'airtime' &&
                            <FormGroupWithIcon >
                                <label>Network</label>
                                <div className="input-with-icon" style={{height: '4rem'}}>
                                    <select 
                                        placeholder="Select network"
                                        name='network'
                                        value={input.network}
                                        style={{color: input.network ? '#000000' : '#667085'}}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    >
                                        <option value="">Select network</option>
                                        <option value="MTN">MTN</option>
                                        <option value="GLO">GLO</option>
                                        <option value="AIRTEL">AIRTEL</option>
                                        <option value="ETISALAT">ETISALAT</option>
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        }
                        { hasFirstAndLastName.includes(type) &&
                            <>
                                <FormGroup height='4'>
                                    <label>First Name</label>
                                    <input 
                                        placeholder='Enter first name'
                                        name='firstName'
                                        value={input.firstName}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup>   
                                <FormGroup height='4'>
                                    <label>Last Name</label>
                                    <input 
                                        placeholder='Enter last name'
                                        name='lastName'
                                        value={input.lastName}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup>   
                            </>
                        }
                        { hasUsername.includes(type) &&
                            <FormGroup height='4'>
                                <label>Username</label>
                                <input 
                                    placeholder='Enter username'
                                    name='username'
                                    value={input.username}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        { ['login-report', 'customer-login'].includes(type) &&
                            <>
                                { type === 'customer-login' &&
                                    <FormGroupWithIcon >
                                        <label>Platform</label>
                                        <div className="input-with-icon" style={{height: '4rem'}}>
                                            <select 
                                                name='isMobile'
                                                value={input.isMobile}
                                                style={{color: input.isMobile ? '#000000' : '#667085'}}
                                                onChange={handleInput}
                                                autoComplete="off"
                                            >
                                                <option value="">Select Type</option>
                                                <option value='Mobile'>Mobile</option>
                                                <option value="Web">Web</option>
                                            </select>
                                            <FiChevronDown className="input-with-icon-tag" />
                                        </div>
                                    </FormGroupWithIcon>
                                }
                                <FormGroupWithIcon >
                                    <label>Login Status</label>
                                    <div className="input-with-icon" style={{height: '4rem'}}>
                                        <select 
                                            name='isSuccessful'
                                            value={input.isSuccessful}
                                            style={{color: input.isSuccessful ? '#000000' : '#667085'}}
                                            onChange={handleInput}
                                            autoComplete="off"
                                        >
                                            <option value="">Select status</option>
                                            <option value='Success'>Success</option>
                                            <option value="Failed">Failed</option>
                                        </select>
                                        <FiChevronDown className="input-with-icon-tag" />
                                    </div>
                                </FormGroupWithIcon>
                            </>
                        }
                        { hasAmount.includes(type) &&
                            <FormGroup height='4'>
                                <label>Amount</label>
                                <input 
                                    placeholder='Enter amount'
                                    name='amount'
                                    value={input.amount}
                                    onChange={handleInput}
                                    onKeyDown={handleTypeNumberControl}
                                    onKeyUp={handleTypeNumberControl}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        { hasProfileID.includes(type) &&
                            <FormGroup height='4'>
                                <label>Profile ID</label>
                                <input 
                                    placeholder='Enter profile id'
                                    name='profileID'
                                    value={input.profileID}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        { type === 'customer-profile' &&
                            <FormGroupWithIcon >
                                <label>Onboarding Method</label>
                                <div className="input-with-icon" style={{height: '4rem'}}>
                                    <select 
                                        name='onboardingMethod'
                                        value={input.onboardingMethod}
                                        style={{color: input.onboardingMethod ? '#000000' : '#667085'}}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    >
                                        <option value="">Select method</option>
                                        <option value='NewCustomer'>New Customer</option>
                                        <option value="ExistingCustomer">Existing Customer</option>
                                        <option value="V1Migration">V1 Migration</option>
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        }
                        { type === 'audit-report' &&
                            <FormGroup height='4'>
                                <label>Model</label>
                                <input 
                                    placeholder='Enter model'
                                    name='model'
                                    value={input.model}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        { type === 'users' &&
                            <FormGroupWithIcon >
                                <label>Role</label>
                                <div className="input-with-icon" style={{height: '4rem'}}>
                                    <select 
                                        name='role'
                                        value={input.role}
                                        style={{color: input.role ? '#000000' : '#667085'}}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    >
                                        <option value="">Select role</option>
                                        <option value='SA'>Qore Admin</option>
                                        <option value="Admin">Institution Admin</option>
                                        <option value="User">Institution User</option>
                                    </select>
                                    <FiChevronDown className="input-with-icon-tag" />
                                </div>
                            </FormGroupWithIcon>
                        }
                        { hasInstitutionCode.includes(type) &&
                            <FormGroup height='4'>
                                <label>Institution Code</label>
                                <input 
                                    placeholder='Enter code'
                                    name='institutionCode'
                                    value={input.institutionCode}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        { type === 'institutions' &&
                            <>
                                <FormGroup height='4'>
                                    <label>Institution Name</label>
                                    <input 
                                        placeholder='Enter name'
                                        name='name'
                                        value={input.name}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup> 
                                <FormGroup height='4'>
                                    <label>Alias</label>
                                    <input 
                                        placeholder='Enter alias'
                                        name='alias'
                                        value={input.alias}
                                        onChange={handleInput}
                                        autoComplete="off"
                                    />
                                </FormGroup> 
                            </>
                        }
                        { hasStatus.includes(type) &&
                            <FormGroup height='4'>
                                <label>Status</label>
                                <input 
                                    placeholder='Enter status'
                                    name='status'
                                    value={input.status}
                                    onChange={handleInput}
                                    autoComplete="off"
                                />
                            </FormGroup> 
                        }
                        <Button 
                            width='11.2' height='4' topMargin='1'
                            borderColor='#0D968F' hoverBgColor='#fff' 
                            hoverColor='#0D968F' titleSize='1.4'
                        >
                            Apply filter
                        </Button>
                    </Form>
                </CustomContainer>
            </AbsoluteContainer>
        </>
    )
}